import { computed, Directive, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardVariants = cva('rounded-lg border border-gray-200 bg-white shadow-md overflow-hidden', {
  variants: {},
  defaultVariants: {},
});
export type CardVariants = VariantProps<typeof cardVariants>;

@Directive({
  selector: '[libCard]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardDirective {
  readonly userClass = input<ClassValue>('');

  protected _computedClass = computed(() => mergeClasses(cardVariants(), this.userClass()));
}
