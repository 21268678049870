import {
  booleanAttribute,
  Component,
  computed,
  EventEmitter,
  forwardRef,
  Input,
  input,
  Output,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';
import { SwitchBaseComponent, SwitchThumbDirective } from './switch-base';

export const APP_SWITCH_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitchComponent),
  multi: true,
};

export const switchVariants = cva(
  'data-[state=checked]:bg-primary-500  data-[state=unchecked]:bg-gray-400 group inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 ',
  {
    variants: {
      size: {
        sm: 'h-5 w-10 text-sm',
        default: 'h-6 w-11 text-base',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

export type SwitchVariants = VariantProps<typeof switchVariants>;

@Component({
  selector: ' lib-switch',
  imports: [SwitchBaseComponent, SwitchThumbDirective],
  standalone: true,
  host: {
    class: 'block',
    '[attr.id]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
  template: `
    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
    <label class="flex items-center gap-2">
      <lib-switch-base
        [class]="_computedClass()"
        [checked]="_checked()"
        (changed)="_handleChange($event)"
        (touched)="_onTouched()"
        [disabled]="_disabled()"
        [id]="id"
        [aria-label]="ariaLabel"
        [aria-labelledby]="ariaLabelledby"
        [aria-describedby]="ariaDescribedby"
        [innerTextUnchecked]="innerTextUnchecked()"
        [innerTextChecked]="innerTextChecked()">
        <span [size]="size()" libSwitchThumb></span>
      </lib-switch-base>

      <span class="cursor-pointer">
        <ng-content />
      </span>
    </label>
  `,
  providers: [APP_SWITCH_VALUE_ACCESSOR],
})
export class SwitchComponent {
  readonly userClass = input<ClassValue>('');
  readonly size = input<SwitchVariants['size']>('default');
  readonly innerTextChecked = input('', {
    transform: (value: string) => value.slice(0, 2),
  });
  readonly innerTextUnchecked = input('', {
    transform: (value: string) => value.slice(0, 2),
  });
  @Output()
  public changed = new EventEmitter<boolean>();
  /** Used to set the id on the underlying brn element. */
  @Input()
  id: string | null = null;
  /** Used to set the aria-label attribute on the underlying brn element. */
  @Input('aria-label')
  ariaLabel: string | null = null;
  /** Used to set the aria-labelledby attribute on the underlying brn element. */
  @Input('aria-labelledby')
  ariaLabelledby: string | null = null;
  /** Used to set the aria-describedby attribute on the underlying brn element. */
  @Input('aria-describedby')
  ariaDescribedby: string | null = null;
  protected readonly _disabled = signal(false);
  protected _computedClass = computed(() =>
    mergeClasses(
      switchVariants({
        size: this.size(),
      }),
      this._disabled() ? 'cursor-not-allowed opacity-50' : '',
      this.userClass()
    )
  );

  protected _checked = signal(false);

  @Input({ transform: booleanAttribute })
  set checked(value: boolean) {
    this._checked.set(value);
  }

  @Input({ transform: booleanAttribute })
  set disabled(value: boolean) {
    this._disabled.set(value);
  }

  /** CONROL VALUE ACCESSOR */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this.checked = !!value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  protected _handleChange(value: boolean): void {
    this._checked.set(value);
    this._onChange(value);
    this.changed.emit(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars,,@typescript-eslint/no-explicit-any
  protected _onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onTouched = () => {};
}
