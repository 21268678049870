import {
  iconoirArrowRight,
  iconoirBluetoothTag,
  iconoirBluetooth,
  iconoirDownload,
  iconoirMenu,
  iconoirMoreVert,
  iconoirNavArrowDown,
  iconoirNavArrowRight,
  iconoirPlus,
  iconoirXmark,
} from '@ng-icons/iconoir';

export const ngIcons = {
  iconoirNavArrowDown,
  iconoirNavArrowRight,
  iconoirMenu,
  iconoirArrowRight,
  iconoirXmark,
  iconoirPlus,
  iconoirDownload,
  iconoirMoreVert,
  iconoirBluetoothTag,
  iconoirBluetooth,
};
