import { isPlatformBrowser } from '@angular/common';
import { computed, Directive, ElementRef, inject, Input, input, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { NgControl } from '@angular/forms';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

let nextId = 0;

@Directive({
  standalone: true,
  host: {
    '[id]': '_id()',
    '[class.ng-invalid]': 'this._ngControl?.invalid || null',
    '[class.ng-dirty]': 'this._ngControl?.dirty || null',
    '[class.ng-valid]': 'this._ngControl?.valid || null',
    '[class.ng-touched]': 'this._ngControl?.touched || null',
  },
})
export class NgLabelDirective implements OnInit {
  protected readonly _id = signal(`ng-label-${nextId++}`);
  protected readonly _ngControl = inject(NgControl, { optional: true });
  private readonly _isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private readonly _element = inject(ElementRef).nativeElement;
  private _changes?: MutationObserver;
  private readonly _dataDisabled = signal<boolean | 'auto'>('auto');
  public readonly dataDisabled = this._dataDisabled.asReadonly();

  get id() {
    return this._id();
  }

  @Input()
  set id(id: string) {
    this._id.set(id || this._id());
  }

  ngOnInit(): void {
    if (!this._isBrowser) return;
    this._changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.attributeName !== 'data-disabled') return;
        const state =
          // eslint-disable-next-line
          (mutation.target as any).attributes.getNamedItem(mutation.attributeName)?.value === 'true';
        this._dataDisabled.set(state ?? 'auto');
      });
    });
    this._changes?.observe(this._element, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }
}

const labelVariants = cva(
  'text-sm font-medium leading-none [&>[libInput]]:my-1 [&:has([libInput]:disabled)]:cursor-not-allowed [&:has([libInput]:disabled)]:opacity-70',
  {
    variants: {
      variant: {
        default: 'block pb-1.5',
        floating: 'absolute -translate-y-4 top-2 start-2 bg-white px-1.5',
      },
      error: {
        auto: '[&:has([libInput].ng-invalid.ng-touched)]:text-red-500',
        true: 'text-red-500',
      },
      disabled: {
        auto: '[&:has([libInput]:disabled)]:opacity-70',
        true: 'opacity-70',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      error: 'auto',
    },
  }
);
export type LabelVariants = VariantProps<typeof labelVariants>;

@Directive({
  selector: '[libLabel], lib-label',
  standalone: true,
  hostDirectives: [
    {
      directive: NgLabelDirective,
      inputs: ['id'],
    },
  ],
  host: {
    '[class]': '_computedClass()',
  },
})
export class LabelDirective {
  public readonly userClass = input<ClassValue>('');
  variant = input<LabelVariants['variant']>('default');
  error = input<LabelVariants['error']>('auto');
  private readonly _label = inject(NgLabelDirective, { host: true });
  protected readonly _computedClass = computed(() =>
    mergeClasses(
      labelVariants({
        variant: this.variant(),
        error: this.error(),
        disabled: this._label?.dataDisabled() ?? 'auto',
      }),
      '[&.ng-invalid.ng-touched]:text-red-500',
      this.userClass()
    )
  );
}
