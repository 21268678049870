import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { NgClass, NgComponentOutlet, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, output, TemplateRef, Type } from '@angular/core';
import { NgIconComponent } from '@ng-icons/core';
import { CardModule } from './card';

export interface AccordionItem {
  title: string;
  content: Type<unknown> | TemplateRef<unknown>;
  inputs?: Record<string, unknown>;
  expanded?: boolean;
}

@Component({
  selector: 'lib-accordions',
  standalone: true,
  imports: [
    forwardRef(() => CdkAccordionModule),
    NgClass,
    NgIconComponent,
    NgComponentOutlet,
    CardModule,
    NgTemplateOutlet,
    NgComponentOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (accordions().length) {
      <cdk-accordion [multi]="true" class="flex flex-col gap-5">
        @for (accordion of accordions(); track accordion.title; let index = $index) {
          <cdk-accordion-item
            libCard
            (keydown.enter)="preventToggle($event)"
            #accordionItem="cdkAccordionItem"
            [expanded]="accordion.expanded"
            [attr.id]="'accordion-header-' + index"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + index">
            <button
              tabindex="-1"
              (click)="onToggle(accordionItem, accordion, index)"
              class="flex w-full select-none items-center justify-between p-4 hover:bg-gray-50">
              <h3 class="truncate text-lg font-semibold">
                {{ accordion.title }}
              </h3>
              <ng-icon [ngClass]="{ 'rotate-180': accordionItem.expanded }" name="iconoirNavArrowDown" />
            </button>
            @defer (when accordionItem.expanded) {
              <div
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index"
                class="cursor-default p-4">
                @if (isComponent(accordion.content)) {
                  <ng-container *ngComponentOutlet="accordion.content; inputs: accordion?.inputs"></ng-container>
                } @else {
                  <ng-container *ngTemplateOutlet="accordion.content"></ng-container>
                }
              </div>
            }
          </cdk-accordion-item>
        }
      </cdk-accordion>
    }
  `,
})
export class AccordionsComponent {
  accordions = input.required<AccordionItem[]>();
  opened = output<{ item: AccordionItem; index: number }>();
  closed = output<{ item: AccordionItem; index: number }>();

  isComponent(content: Type<unknown> | TemplateRef<unknown>): content is Type<unknown> {
    return content instanceof Function;
  }

  onToggle(accordion: CdkAccordionItem, item: AccordionItem, index: number) {
    accordion.toggle();
    item.expanded = !item.expanded;
    if (item.expanded) {
      this.opened.emit({ item, index });
    } else {
      this.closed.emit({ item, index });
    }
  }

  preventToggle(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
