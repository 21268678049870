import { Component, effect, input, signal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButton } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lib-logs-session',
  standalone: true,
  imports: [CommonModule, SelectButton, FormsModule],
  template: `
    <div class="card mb-6 flex justify-center">
      <p-select-button
        class="filter-buttons"
        [options]="filterOptions"
        [ngModel]="value()"
        (ngModelChange)="value.set($event)"
        [multiple]="true"
        optionLabel="name"
        size="small"
        optionValue="value">
      </p-select-button>
    </div>
    <div class="terminal-container">
      <div class="terminal-line" *ngFor="let log of formattedLogs()" [innerHTML]="log"></div>
      <div class="blinking-cursor">|</div>
    </div>
  `,
  styles: [
    `
      /* General Layout */
      .filters-toolbar {
        display: flex;
        align-items: center;
        background-color: #2c2c2c;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
      }

      .filters-toolbar h3 {
        margin: 0;
        margin-right: 20px;
        color: #ffffff;
        font-weight: normal;
      }

      .filter-buttons {
        flex-grow: 1;
      }

      .terminal-container {
        background-color: #1e1e1e;
        color: #ffffff;
        font-family: Consolas, 'Courier New', Courier, monospace;
        font-size: 14px;
        line-height: 1.4em;
        padding: 10px;
        border-radius: 5px;
        overflow: auto;
        height: 80vh;
        white-space: pre-wrap;
      }

      .terminal-line {
        margin-bottom: 2px;
      }

      /* Log Formatting Classes */
      .timestamp {
        color: #999999;
      }

      .brackets {
        color: #aaaaaa;
      }

      .blinking-cursor {
        display: inline-block;
        color: #ffffff;
        animation: blink 500ms infinite alternate;
      }

      @keyframes blink {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LogsSessionComponent {
  logs = input.required<string[]>();
  formattedLogs = signal<SafeHtml[]>([]);

  value = signal<number[]>([]);

  filterOptions: any[] = [
    { name: 'Info', value: 1 },
    { name: 'Warn', value: 2 },
    { name: 'Debug', value: 3 },
    { name: 'Error', value: 4 },
  ];

  constructor(private sanitizer: DomSanitizer) {
    effect(
      () => {
        const rawLogs = this.logs();
        const selected = this.value();

        // If no filters are selected, show all logs
        const filtered =
          selected.length === 0
            ? rawLogs
            : rawLogs.filter(line => {
                const hasInf = line.includes('INF');
                const hasWrn = line.includes('WRN');
                const hasDbg = line.includes('DBG');
                const hasErr = line.includes('ERR');

                const matchInf = selected.includes(1) && hasInf;
                const matchWrn = selected.includes(2) && hasWrn;
                const matchDebug = selected.includes(3) && hasDbg;
                const matchErr = selected.includes(4) && hasErr;
                return matchInf || matchWrn || matchErr || matchDebug;
              });

        const formatted = filtered.map(log => this.formatLogLine(log));
        this.formattedLogs.set(formatted);
      },
      { allowSignalWrites: true }
    );
  }

  private formatLogLine(rawLine: string): SafeHtml {
    // eslint-disable-next-line no-control-regex
    const ansiPattern = new RegExp('\\u001b\\[(\\d+(?:;\\d+)*)m(.*?)\\u001b\\[0m', 'g');

    let line = rawLine.replace(ansiPattern, (match, codes, text) => {
      const codeArr = codes.split(';').map(Number);
      const colorCode = codeArr[0]; // Main color code is usually the first
      const isBright = codeArr.includes(1);

      let cssColor = '#ffffff'; // Default if none match
      switch (colorCode) {
        case 31: // Red
          cssColor = isBright ? '#ff4c4c' : '#ff0000';
          break;
        case 32: // Green
          cssColor = isBright ? '#4caf50' : '#00ff00';
          break;
        case 33: // Yellow
          cssColor = isBright ? '#ffff00' : '#cccc00';
          break;
        case 34: // Blue
          cssColor = isBright ? '#00bcd4' : '#0000ff';
          break;
        case 35: // Magenta
          cssColor = isBright ? '#ff00ff' : '#cc00cc';
          break;
        case 36: // Cyan
          cssColor = '#00ffff'; // Cyan is typically bright enough by default
          break;
        case 37: // White/Gray
          cssColor = isBright ? '#ffffff' : '#dddddd';
          break;
        default:
          cssColor = '#ffffff';
      }
      return `<span style="color: ${cssColor} !important;">${text}</span>`;
    });

    // Optional: Further formatting for timestamps, brackets, prefixes, etc.
    line = line.replace(/\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\]/g, '<span class="timestamp">[$1]</span>');
    line = line.replace(/\[(\s*\d+)\]/g, '<span class="brackets">[$1]</span>');

    return this.sanitizer.bypassSecurityTrustHtml(line);
  }
}
