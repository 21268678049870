import {
  booleanAttribute,
  Component,
  computed,
  EventEmitter,
  forwardRef,
  input,
  model,
  Output,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { iconoirCheck } from '@ng-icons/iconoir';
import { mergeClasses } from '@wheere-front/shared-util';
import type { ClassValue } from 'clsx';
import { CheckboxBaseComponent } from './checkbox-base.component';

const CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

@Component({
  selector: 'lib-checkbox',
  standalone: true,
  imports: [CheckboxBaseComponent, NgIconComponent],
  template: `
    <lib-checkbox-base
      [id]="id()"
      [name]="name()"
      [class]="_computedClass()"
      [checked]="checked()"
      [disabled]="_disabled()"
      [required]="required()"
      [aria-label]="ariaLabel()"
      [aria-labelledby]="ariaLabelledby()"
      [aria-describedby]="ariaDescribedby()"
      (changed)="_handleChange()"
      (touched)="_onTouched()">
      @if (this.checked() === 'indeterminate') {
        <span class="translate-y-[calc(50% - h-[8%])] mx-0.5 h-[16%] w-full rounded-sm bg-white"></span>
      } @else if (this.checked() === true) {
        <ng-icon name="iconoirCheck" strokeWidth="3" class="pl-px text-white" />
      }
    </lib-checkbox-base>
  `,
  host: {
    class: 'contents',
    '[attr.id]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
  providers: [CHECKBOX_VALUE_ACCESSOR, provideIcons({ iconoirCheck })],
})
export class CheckboxComponent {
  public readonly userClass = input<ClassValue>('');
  /** Used to set the id on the underlying brn element. */
  public readonly id = input<string | null>(null);
  /** Used to set the aria-label attribute on the underlying brn element. */
  public readonly ariaLabel = input<string | null>(null, {
    alias: 'aria-label',
  });
  /** Used to set the aria-labelledby attribute on the underlying brn element. */
  public readonly ariaLabelledby = input<string | null>(null, {
    alias: 'aria-labelledby',
  });
  /** Used to set the aria-describedby attribute on the underlying brn element. */
  public readonly ariaDescribedby = input<string | null>(null, {
    alias: 'aria-describedby',
  });
  public readonly checked = model<boolean | 'indeterminate'>(false);
  public readonly name = input<string | null>(null);
  public readonly required = input(false, { transform: booleanAttribute });
  public readonly disabled = input(false, { transform: booleanAttribute });
  // icon inputs
  public readonly checkIconName = input<string>('lucideCheck');
  public readonly checkIconClass = input<string>('');
  @Output()
  public changed = new EventEmitter<boolean>();
  protected readonly _disabled = signal(false);
  protected _computedClass = computed(() =>
    mergeClasses(
      'size-5 group inline-flex border border-gray-200 shrink-0 cursor-pointer items-center rounded-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black' +
        ' focus-visible:ring-offset-2 focus-visible:ring-offset-black data-[state=checked]:text-black data-[state=checked]:bg-primary-500 data-[state=unchecked]:bg-gray-100',
      this.userClass(),
      this._disabled() ? 'cursor-not-allowed opacity-50' : '',
      this.checked() === 'indeterminate' ? 'bg-primary-500' : ''
    )
  );

  /** CONROL VALUE ACCESSOR */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this.checked.set(!!value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled.set(isDisabled);
  }

  protected _handleChange(): void {
    if (this._disabled()) return;

    const previousChecked = this.checked();
    this.checked.set(previousChecked === 'indeterminate' ? true : !previousChecked);
    this._onChange(!previousChecked);
    this.changed.emit(!previousChecked);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars,,@typescript-eslint/no-explicit-any
  protected _onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onTouched = () => {};
}
