import { computed, Directive, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardContentVariants = cva('p-3 lg:p-5 pt-0 lg:pt-0', {
  variants: {},
  defaultVariants: {},
});

export type CardContentVariants = VariantProps<typeof cardContentVariants>;

@Directive({
  selector: '[libCardContent]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardContentDirective {
  readonly userClass = input<ClassValue>('');

  protected _computedClass = computed(() => mergeClasses(cardContentVariants(), this.userClass()));
}
