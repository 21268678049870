import * as i0 from '@angular/core';
import { NgModule, Optional, SkipSelf, InjectionToken, Injectable, Inject } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';
class Configuration {
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  apiKeys;
  username;
  password;
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  accessToken;
  basePath;
  withCredentials;
  /**
   * Takes care of encoding query- and form-parameters.
   */
  encoder;
  /**
   * Encoding of various path parameter
   * <a href="https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#style-values">styles</a>.
   * <p>
   * See {@link README.md} for more details
   * </p>
   */
  encodeParam;
  /**
   * The keys are the names in the securitySchemes section of the OpenAPI
   * document. They should map to the value used for authentication
   * minus any standard prefixes such as 'Basic' or 'Bearer'.
   */
  credentials;
  constructor(configurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    if (configurationParameters.encodeParam) {
      this.encodeParam = configurationParameters.encodeParam;
    } else {
      this.encodeParam = param => this.defaultEncodeParam(param);
    }
    if (configurationParameters.credentials) {
      this.credentials = configurationParameters.credentials;
    } else {
      this.credentials = {};
    }
  }
  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderContentType(contentTypes) {
    if (contentTypes.length === 0) {
      return undefined;
    }
    const type = contentTypes.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }
  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderAccept(accepts) {
    if (accepts.length === 0) {
      return undefined;
    }
    const type = accepts.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
  lookupCredential(key) {
    const value = this.credentials[key];
    return typeof value === 'function' ? value() : value;
  }
  defaultEncodeParam(param) {
    // This implementation exists as fallback for missing configuration
    // and for backwards compatibility to older typescript-angular generator versions.
    // It only works for the 'simple' parameter style.
    // Date-handling only works for the 'date-time' format.
    // All other styles and Date-formats are probably handled incorrectly.
    //
    // But: if that's all you need (i.e.: the most common use-case): no need for customization!
    const value = param.dataFormat === 'date-time' && param.value instanceof Date ? param.value.toISOString() : param.value;
    return encodeURIComponent(String(value));
  }
}
class ApiModule {
  static forRoot(configurationFactory) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: Configuration,
        useFactory: configurationFactory
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: []
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
class CustomHttpParameterCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}
const BASE_PATH = new InjectionToken('basePath');
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class AddressService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  updateGatewayAddress(gatewayDAOId, gateway, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateGatewayAddress.');
    }
    if (gateway === null || gateway === undefined) {
      throw new Error('Required parameter gateway was null or undefined when calling updateGatewayAddress.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/address`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: gateway,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function AddressService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AddressService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AddressService,
    factory: AddressService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddressService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ApplicationParametersService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getAppParameters(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/app-params`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAppParameters(appParametersId, appParameters, observe = 'body', reportProgress = false, options) {
    if (appParametersId === null || appParametersId === undefined) {
      throw new Error('Required parameter appParametersId was null or undefined when calling updateAppParameters.');
    }
    if (appParameters === null || appParameters === undefined) {
      throw new Error('Required parameter appParameters was null or undefined when calling updateAppParameters.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/app-params/${this.configuration.encodeParam({
      name: "appParametersId",
      value: appParametersId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: appParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ApplicationParametersService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApplicationParametersService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApplicationParametersService,
    factory: ApplicationParametersService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationParametersService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class AttenuatorService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  updateAllDdsAtt(gatewayDAOId, ddsAtt, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllDdsAtt.');
    }
    if (ddsAtt === null || ddsAtt === undefined) {
      throw new Error('Required parameter ddsAtt was null or undefined when calling updateAllDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsAtt,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function AttenuatorService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AttenuatorService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AttenuatorService,
    factory: AttenuatorService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AttenuatorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ClockConfigService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getClockConfig(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getClockConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clock-config`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateClockConfig(gatewayDAOId, clockConfig, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateClockConfig.');
    }
    if (clockConfig === null || clockConfig === undefined) {
      throw new Error('Required parameter clockConfig was null or undefined when calling updateClockConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clock-config`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: clockConfig,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ClockConfigService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClockConfigService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ClockConfigService,
    factory: ClockConfigService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClockConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ClockReg2Service {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getClockReg2Status(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getClockReg2Status.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clockreg2status`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ClockReg2Service_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClockReg2Service)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ClockReg2Service,
    factory: ClockReg2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClockReg2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DdsService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getDds(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDds.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling getDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDdsStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDds(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllDds(gatewayDAOId, dds, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllDds.');
    }
    if (dds === null || dds === undefined) {
      throw new Error('Required parameter dds was null or undefined when calling updateAllDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: dds,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDds(gatewayDAOId, ddsId, dds, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDds.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling updateDds.');
    }
    if (dds === null || dds === undefined) {
      throw new Error('Required parameter dds was null or undefined when calling updateDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: dds,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DdsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DdsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DdsService,
    factory: DdsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DdsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DdsFtcService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getDdsFtc(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsFtc.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling getDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDdsFtc(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllFtc(gatewayDAOId, ddsFtc, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllFtc.');
    }
    if (ddsFtc === null || ddsFtc === undefined) {
      throw new Error('Required parameter ddsFtc was null or undefined when calling updateAllFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsFtc,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDdsFtc(gatewayDAOId, ddsId, ddsFtc, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDdsFtc.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling updateDdsFtc.');
    }
    if (ddsFtc === null || ddsFtc === undefined) {
      throw new Error('Required parameter ddsFtc was null or undefined when calling updateDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsFtc,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DdsFtcService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DdsFtcService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DdsFtcService,
    factory: DdsFtcService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DdsFtcService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DdsattService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getDdsAtt(gatewayDAOId, ddsAttId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsAtt.');
    }
    if (ddsAttId === null || ddsAttId === undefined) {
      throw new Error('Required parameter ddsAttId was null or undefined when calling getDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt/${this.configuration.encodeParam({
      name: "ddsAttId",
      value: ddsAttId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDdsAtt(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllDdsAtt(gatewayDAOId, ddsAtt, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllDdsAtt.');
    }
    if (ddsAtt === null || ddsAtt === undefined) {
      throw new Error('Required parameter ddsAtt was null or undefined when calling updateAllDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsAtt,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDdsAtt(gatewayDAOId, ddsAttId, ddsAtt, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDdsAtt.');
    }
    if (ddsAttId === null || ddsAttId === undefined) {
      throw new Error('Required parameter ddsAttId was null or undefined when calling updateDdsAtt.');
    }
    if (ddsAtt === null || ddsAtt === undefined) {
      throw new Error('Required parameter ddsAtt was null or undefined when calling updateDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt/${this.configuration.encodeParam({
      name: "ddsAttId",
      value: ddsAttId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsAtt,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DdsattService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DdsattService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DdsattService,
    factory: DdsattService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DdsattService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DefaultParametersService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  listDefaultParameters(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/default-parameters`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllDefaultParameter(defaultParams, observe = 'body', reportProgress = false, options) {
    if (defaultParams === null || defaultParams === undefined) {
      throw new Error('Required parameter defaultParams was null or undefined when calling updateAllDefaultParameter.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/default-parameters`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: defaultParams,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DefaultParametersService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DefaultParametersService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DefaultParametersService,
    factory: DefaultParametersService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultParametersService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class FirmwareService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getFirmware(filename, observe = 'body', reportProgress = false, options) {
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (filename !== undefined && filename !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, filename, 'filename');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/firmware/`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listFirmwares(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/firmwares`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  upgradeFirmware(gatewayDAOId, firmware, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling upgradeFirmware.');
    }
    if (firmware === null || firmware === undefined) {
      throw new Error('Required parameter firmware was null or undefined when calling upgradeFirmware.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/upgradeFirmware`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: firmware,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  uploadFirmware(firmwareFileUpload, observe = 'body', reportProgress = false, options) {
    if (firmwareFileUpload === null || firmwareFileUpload === undefined) {
      throw new Error('Required parameter firmwareFileUpload was null or undefined when calling uploadFirmware.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/firmware`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: firmwareFileUpload,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function FirmwareService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FirmwareService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FirmwareService,
    factory: FirmwareService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FirmwareService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class GatewayService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  countGatewaysBySite(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling countGatewaysBySite.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, id, 'id');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/by-site/count`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  gatewaysAssignPut(gatewaysAssignPutRequest, observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/assign`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: gatewaysAssignPutRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  gatewaysOrderPut(gateway, observe = 'body', reportProgress = false, options) {
    if (gateway === null || gateway === undefined) {
      throw new Error('Required parameter gateway was null or undefined when calling gatewaysOrderPut.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/order`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: gateway,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getAllGatewaysFiltered(page, size, sort, getAllGatewaysFilteredRequest, observe = 'body', reportProgress = false, options) {
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, sort, 'sort');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/sales`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getAllGatewaysFilteredRequest,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getClockConfig(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getClockConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clock-config`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getClockReg2Status(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getClockReg2Status.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clockreg2status`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDds(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDds.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling getDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDdsAtt(gatewayDAOId, ddsAttId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsAtt.');
    }
    if (ddsAttId === null || ddsAttId === undefined) {
      throw new Error('Required parameter ddsAttId was null or undefined when calling getDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt/${this.configuration.encodeParam({
      name: "ddsAttId",
      value: ddsAttId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDdsFtc(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsFtc.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling getDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDdsStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getDdsStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getGateway(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getGateway.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getGatewayStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getGatewayStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/status`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getPllLockStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getPllLockStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/plllockstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getVbias(gatewayDAOId, vbiasId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getVbias.');
    }
    if (vbiasId === null || vbiasId === undefined) {
      throw new Error('Required parameter vbiasId was null or undefined when calling getVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias/${this.configuration.encodeParam({
      name: "vbiasId",
      value: vbiasId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getVbiasStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getVbiasStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbiasstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDds(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDdsAtt(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listDdsFtc(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listGateways(first, limit, observe = 'body', reportProgress = false, options) {
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (first !== undefined && first !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, first, 'first');
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, limit, 'limit');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listGatewaysBySite(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listGatewaysBySite.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, id, 'id');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/by-site`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listVbias(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  resendVbias(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling resendVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  resetGateway(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling resetGateway.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/reset`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  resetGatewayFactorySettings(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling resetGatewayFactorySettings.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/factory-setting`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  switchParis100khz(gatewayDAOId, paris10khzactivated, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling switchParis100khz.');
    }
    if (paris10khzactivated === null || paris10khzactivated === undefined) {
      throw new Error('Required parameter paris10khzactivated was null or undefined when calling switchParis100khz.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (paris10khzactivated !== undefined && paris10khzactivated !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, paris10khzactivated, 'paris10khzactivated');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/paris100khz`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllDds(gatewayDAOId, dds, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllDds.');
    }
    if (dds === null || dds === undefined) {
      throw new Error('Required parameter dds was null or undefined when calling updateAllDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: dds,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllDdsAtt(gatewayDAOId, ddsAtt, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllDdsAtt.');
    }
    if (ddsAtt === null || ddsAtt === undefined) {
      throw new Error('Required parameter ddsAtt was null or undefined when calling updateAllDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsAtt,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAllFtc(gatewayDAOId, ddsFtc, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAllFtc.');
    }
    if (ddsFtc === null || ddsFtc === undefined) {
      throw new Error('Required parameter ddsFtc was null or undefined when calling updateAllFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsFtc,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateAttenuatorConfig(gatewayDAOId, updateAttenuatorConfigRequest, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateAttenuatorConfig.');
    }
    if (updateAttenuatorConfigRequest === null || updateAttenuatorConfigRequest === undefined) {
      throw new Error('Required parameter updateAttenuatorConfigRequest was null or undefined when calling updateAttenuatorConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/config/attenuator`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateAttenuatorConfigRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateClockConfig(gatewayDAOId, clockConfig, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateClockConfig.');
    }
    if (clockConfig === null || clockConfig === undefined) {
      throw new Error('Required parameter clockConfig was null or undefined when calling updateClockConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/clock-config`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: clockConfig,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateClockConfigv3(gatewayDAOId, updateClockConfigv3Request, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateClockConfigv3.');
    }
    if (updateClockConfigv3Request === null || updateClockConfigv3Request === undefined) {
      throw new Error('Required parameter updateClockConfigv3Request was null or undefined when calling updateClockConfigv3.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/config/clock`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateClockConfigv3Request,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateClockTypeConfig(gatewayDAOId, updateClockTypeConfigRequest, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateClockTypeConfig.');
    }
    if (updateClockTypeConfigRequest === null || updateClockTypeConfigRequest === undefined) {
      throw new Error('Required parameter updateClockTypeConfigRequest was null or undefined when calling updateClockTypeConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/config/clock/type`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateClockTypeConfigRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateCustomerIdForGateways(updateCustomerIdForGatewaysRequest, observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateways/updateCustomerId`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateCustomerIdForGatewaysRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDds(gatewayDAOId, ddsId, dds, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDds.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling updateDds.');
    }
    if (dds === null || dds === undefined) {
      throw new Error('Required parameter dds was null or undefined when calling updateDds.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/dds/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: dds,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDdsAtt(gatewayDAOId, ddsAttId, ddsAtt, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDdsAtt.');
    }
    if (ddsAttId === null || ddsAttId === undefined) {
      throw new Error('Required parameter ddsAttId was null or undefined when calling updateDdsAtt.');
    }
    if (ddsAtt === null || ddsAtt === undefined) {
      throw new Error('Required parameter ddsAtt was null or undefined when calling updateDdsAtt.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsatt/${this.configuration.encodeParam({
      name: "ddsAttId",
      value: ddsAttId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsAtt,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateDdsFtc(gatewayDAOId, ddsId, ddsFtc, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateDdsFtc.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling updateDdsFtc.');
    }
    if (ddsFtc === null || ddsFtc === undefined) {
      throw new Error('Required parameter ddsFtc was null or undefined when calling updateDdsFtc.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/ddsftc/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: ddsFtc,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateGateway(gatewayDAOId, gateway, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateGateway.');
    }
    if (gateway === null || gateway === undefined) {
      throw new Error('Required parameter gateway was null or undefined when calling updateGateway.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: gateway,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateGatewayAddress(gatewayDAOId, gateway, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateGatewayAddress.');
    }
    if (gateway === null || gateway === undefined) {
      throw new Error('Required parameter gateway was null or undefined when calling updateGatewayAddress.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/address`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: gateway,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateGatewaySite(gatewayDAOId, body, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateGatewaySite.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateGatewaySite.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/site`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: body,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateSignalConfig(gatewayDAOId, updateSignalConfigRequest, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateSignalConfig.');
    }
    if (updateSignalConfigRequest === null || updateSignalConfigRequest === undefined) {
      throw new Error('Required parameter updateSignalConfigRequest was null or undefined when calling updateSignalConfig.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/config/signal`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateSignalConfigRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateVbias(gatewayDAOId, vbiasId, vbias, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateVbias.');
    }
    if (vbiasId === null || vbiasId === undefined) {
      throw new Error('Required parameter vbiasId was null or undefined when calling updateVbias.');
    }
    if (vbias === null || vbias === undefined) {
      throw new Error('Required parameter vbias was null or undefined when calling updateVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias/${this.configuration.encodeParam({
      name: "vbiasId",
      value: vbiasId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: vbias,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  upgradeFirmware(gatewayDAOId, firmware, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling upgradeFirmware.');
    }
    if (firmware === null || firmware === undefined) {
      throw new Error('Required parameter firmware was null or undefined when calling upgradeFirmware.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/upgradeFirmware`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: firmware,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function GatewayService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GatewayService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: GatewayService,
    factory: GatewayService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GatewayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class Ina237DataService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getLastIna237Data(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getLastIna237Data.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/last`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getLastIna237DataByDdsId(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getLastIna237DataByDdsId.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling getLastIna237DataByDdsId.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}/last`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listIna237Data(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listIna237Data.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listIna237DataBetween(gatewayDAOId, from, to, interval, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listIna237DataBetween.');
    }
    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling listIna237DataBetween.');
    }
    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling listIna237DataBetween.');
    }
    if (interval === null || interval === undefined) {
      throw new Error('Required parameter interval was null or undefined when calling listIna237DataBetween.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/${this.configuration.encodeParam({
      name: "from",
      value: from,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}/${this.configuration.encodeParam({
      name: "to",
      value: to,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}/${this.configuration.encodeParam({
      name: "interval",
      value: interval,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listIna237DataBetweenByDdsId(gatewayDAOId, from, to, ddsId, interval, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listIna237DataBetweenByDdsId.');
    }
    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling listIna237DataBetweenByDdsId.');
    }
    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling listIna237DataBetweenByDdsId.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling listIna237DataBetweenByDdsId.');
    }
    if (interval === null || interval === undefined) {
      throw new Error('Required parameter interval was null or undefined when calling listIna237DataBetweenByDdsId.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: "from",
      value: from,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}/${this.configuration.encodeParam({
      name: "to",
      value: to,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}/${this.configuration.encodeParam({
      name: "interval",
      value: interval,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listIna237DataByDdsId(gatewayDAOId, ddsId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listIna237DataByDdsId.');
    }
    if (ddsId === null || ddsId === undefined) {
      throw new Error('Required parameter ddsId was null or undefined when calling listIna237DataByDdsId.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/ina237-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/${this.configuration.encodeParam({
      name: "ddsId",
      value: ddsId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function Ina237DataService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Ina237DataService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Ina237DataService,
    factory: Ina237DataService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ina237DataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class PllLockService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getPllLockStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getPllLockStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/plllockstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function PllLockService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PllLockService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: PllLockService,
    factory: PllLockService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PllLockService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class SiteService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getGatewaysBySiteId(siteId, observe = 'body', reportProgress = false, options) {
    if (siteId === null || siteId === undefined) {
      throw new Error('Required parameter siteId was null or undefined when calling getGatewaysBySiteId.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/sites/${this.configuration.encodeParam({
      name: "siteId",
      value: siteId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/gateways`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateGatewaySite(gatewayDAOId, body, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateGatewaySite.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateGatewaySite.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/site`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: body,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function SiteService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SiteService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SiteService,
    factory: SiteService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SiteService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class VbiasService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getVbias(gatewayDAOId, vbiasId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getVbias.');
    }
    if (vbiasId === null || vbiasId === undefined) {
      throw new Error('Required parameter vbiasId was null or undefined when calling getVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias/${this.configuration.encodeParam({
      name: "vbiasId",
      value: vbiasId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getVbiasStatus(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getVbiasStatus.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbiasstatus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listVbias(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  resendVbias(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling resendVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateVbias(gatewayDAOId, vbiasId, vbias, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling updateVbias.');
    }
    if (vbiasId === null || vbiasId === undefined) {
      throw new Error('Required parameter vbiasId was null or undefined when calling updateVbias.');
    }
    if (vbias === null || vbias === undefined) {
      throw new Error('Required parameter vbias was null or undefined when calling updateVbias.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/gateway/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/vbias/${this.configuration.encodeParam({
      name: "vbiasId",
      value: vbiasId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: vbias,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function VbiasService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || VbiasService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: VbiasService,
    factory: VbiasService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VbiasService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class WeatherDataService {
  httpClient;
  basePath = 'https://gateway.dev.wheere.tech';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getLastWeatherData(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling getLastWeatherData.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/weather-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/last`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listWeatherData(gatewayDAOId, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listWeatherData.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/weather-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  listWeatherDataBetween(gatewayDAOId, from, to, observe = 'body', reportProgress = false, options) {
    if (gatewayDAOId === null || gatewayDAOId === undefined) {
      throw new Error('Required parameter gatewayDAOId was null or undefined when calling listWeatherDataBetween.');
    }
    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling listWeatherDataBetween.');
    }
    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling listWeatherDataBetween.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/weather-data/${this.configuration.encodeParam({
      name: "gatewayDAOId",
      value: gatewayDAOId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}/${this.configuration.encodeParam({
      name: "from",
      value: from,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}/${this.configuration.encodeParam({
      name: "to",
      value: to,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "Date",
      dataFormat: "date-time"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function WeatherDataService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WeatherDataService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WeatherDataService,
    factory: WeatherDataService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WeatherDataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const APIS = [AddressService, ApplicationParametersService, AttenuatorService, ClockConfigService, ClockReg2Service, DdsService, DdsFtcService, DdsattService, DefaultParametersService, FirmwareService, GatewayService, Ina237DataService, PllLockService, SiteService, VbiasService, WeatherDataService];

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const GatewayParameterType = {
  Dds: 'DDS',
  Ddss: 'DDSS',
  Ddsm: 'DDSM',
  Vbias: 'VBIAS',
  Clock: 'CLOCK',
  ClockType: 'CLOCK_TYPE',
  Ftc: 'FTC',
  Att: 'ATT',
  Signal: 'SIGNAL'
};

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const Ina237Type = {
  Seven: 'SEVEN',
  Fifty: 'FIFTY'
};

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const Ina237ValueType = {
  Ampere: 'AMPERE',
  Volt: 'VOLT',
  Power: 'POWER',
  Temperature: 'TEMPERATURE'
};

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const StatusEnum = {
  Online: 'ONLINE',
  Offline: 'OFFLINE',
  Restarting: 'RESTARTING',
  Upgrading: 'UPGRADING'
};

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gateway Manager API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/*
 * Public API Surface of api-gateway
 */

/**
 * Generated bundle index. Do not edit.
 */

export { APIS, AddressService, ApiModule, ApplicationParametersService, AttenuatorService, BASE_PATH, COLLECTION_FORMATS, ClockConfigService, ClockReg2Service, Configuration, CustomHttpParameterCodec, DdsFtcService, DdsService, DdsattService, DefaultParametersService, FirmwareService, GatewayParameterType, GatewayService, Ina237DataService, Ina237Type, Ina237ValueType, PllLockService, SiteService, StatusEnum, VbiasService, WeatherDataService };
