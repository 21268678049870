import { computed, Directive, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardHeaderVariants = cva('flex p-3 lg:p-5', {
  variants: {
    direction: {
      row: 'flex-row items-center space-x-1.5',
      column: 'flex-col space-y-1.5',
    },
  },
  defaultVariants: {
    direction: 'column',
  },
});
export type CardHeaderVariants = VariantProps<typeof cardHeaderVariants>;

@Directive({
  selector: '[libCardHeader]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardHeaderDirective {
  readonly userClass = input<ClassValue>('');
  readonly direction = input<CardHeaderVariants['direction']>('column');
  protected _computedClass = computed(() =>
    mergeClasses(cardHeaderVariants({ direction: this.direction() }), this.userClass())
  );
}
