import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Directive, ElementRef, HostListener, inject, Renderer2, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LegendPosition } from '@swimlane/ngx-charts';
import { map, Observable } from 'rxjs';

// NOTE: this directive to container of ngx-chart to fix the legend position class and resize the chart on window resize
@Directive({
  selector: '[libNgxChart]',
  standalone: true,
  exportAs: 'libNgxChart',
})
export class NgxChartLegendDirective implements AfterViewInit {
  #el = inject(ElementRef);
  #renderer = inject(Renderer2);

  #legendPosition$: Observable<LegendPosition> = inject(BreakpointObserver)
    .observe('(max-width: 768px)')
    .pipe(
      map(({ matches }) => !matches),
      map(isBreakpointLg => (isBreakpointLg ? LegendPosition.Right : LegendPosition.Below))
    );
  legendPosition = toSignal(this.#legendPosition$, {
    initialValue: LegendPosition.Right,
  });
  #view = signal<[number, number]>([0, 0]);
  view = this.#view.asReadonly();

  ngAfterViewInit() {
    this.setChartView();
    this.fixLegendCssClass(this.legendPosition());
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    this.setChartView();
    this.fixLegendCssClass(this.legendPosition());
  }

  private fixLegendCssClass(position: LegendPosition) {
    const legendElement = this.#el.nativeElement.querySelector('.chart-legend');
    if (legendElement) {
      if (position === LegendPosition.Right) {
        this.#renderer.setStyle(legendElement, 'display', 'inline-block');
      } else {
        // NOTES: here is the ngx chart fix for below legend
        this.#renderer.setStyle(legendElement, 'display', 'block');
      }
    }
  }

  private setChartView() {
    const { offsetWidth, offsetHeight } = this.#el.nativeElement;
    this.#view.set([offsetWidth, offsetHeight] as [number, number]);
  }
}
