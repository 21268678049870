import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { InternetService } from '@wheere-front/shared-util';
import { OfflineComponent } from './offline.component';

@Component({
  selector: 'lib-shell',
  standalone: true,
  imports: [NgClass, OfflineComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .shell-content {
      min-height: calc(100vh - var(--topnav-height));
      margin-top: var(--topnav-height);
    }
  `,
  template: `
    <ng-content select="[slot=navbar]" />
    <div class="mx-auto flex w-full justify-center">
      <ng-content select="[slot=sidenav]" />

      <main
        [ngClass]="{ 'bg-gray-50/75': !isHome(), 'p-4': withPadding() }"
        class="shell-content relative mt-14 block w-full">
        @if (isOnline()) {
          <ng-content>
            <p>shell content empty for now</p>
          </ng-content>
        } @else {
          <lib-offline />
        }
      </main>
    </div>
  `,
})
export class ShellComponent {
  isHome = input(false, { transform: booleanAttribute });
  withPadding = input(true, { transform: booleanAttribute });
  isOnline = inject(InternetService).isOnline;
}
