import { computed, Directive, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const buttonVariants = cva(
  'inline-flex items-center whitespace-nowrap justify-center rounded-lg flex-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-60 disabled:cursor-not-allowed ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-primary-500 text-white',
        secondary: 'bg-secondary-500 text-white',
        destructive: 'bg-red-500 text-white',
        outline: 'border hover:bg-gray-100 bg-white',
        icon: 'bg-transparent text-sm text-gray-400 hover:text-gray-900',
      },
      size: {
        xxs: 'h-5 px-2.5 text-xs rounded-md',
        xs: 'h-8 px-2.5 text-xs rounded-md',
        sm: 'h-9 px-3 text-sm',
        default: 'h-10 px-4 text-base',
        lg: 'h-12 px-6 text-lg',
      },
    },
    compoundVariants: [
      {
        variant: 'icon',
        size: 'default',
        class: 'p-2',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;

@Directive({
  selector: '[libBtn], [libButton]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class ButtonDirective {
  readonly userClass = input<ClassValue>('');

  readonly variant = input<ButtonVariants['variant']>('default');
  readonly size = input<ButtonVariants['size']>('default');

  protected _computedClass = computed(() =>
    mergeClasses(
      buttonVariants({
        variant: this.variant(),
        size: this.size(),
      }),
      this.userClass()
    )
  );
}
